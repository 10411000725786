import React from "react";
import { Layout } from "../../layouts/Layout";
import {
  CHECK_ICON,
  SD_WORKING_IMG01,
} from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandSeven } from "../../components/Brand/BrandSeven";
import SERVICES_DETAILS03 from '../../assets/img/services/services_details03e.jpg'

const ServicesDetailsPageThree = () => {
  return (
    <Layout breadcrumb={"Refund"} title={"Refund Policy"}>
      {/* services-details-area */}
      <div class="container"><div class="row justify-content-center">        <div className="services-details-wrap">

          <div className="services-details-content" style={{textTransform:'initial!important'}}>
            <br />
          
            <h2 className="title">Refund Policy </h2>
            <h6>Last updated December, 31, 2020</h6>
            <br />
            <div>
             <p style={{textTransform:'initial'}}>Thank you for your purchase. We hope you are happy with your choice. You are covered by a 14 days refund policy. Please see below for more information on our refund policy. </p>
             <br /><h5>REFUND PROCESS</h5><hr style={{width:'100%', color:'black'}} />
             <p style={{textTransform:'initial'}}>To ask for a refund you need to contact us via email at contact@Forkswap.org and put in the subject line the term "REFUND". Your email must contain your reason for asking for a refund and a proof of purchase. Failing to put "REFUND" 
                in your subject line may substantially increase the refund period.</p><br />
                <h5>ACCEPTABLE GROUNDS FOR ISSUING A REFUND</h5><hr style={{width:'100%', color:'black'}} /><p style={{textTransform:'initial'}}>You are eligible for a refund: -</p>
                <ul style={{listStyle:'square'}}><li>If you requested your refund within 14 days of the date your invoice was issued.</li><li>If you have a subscription with us and your product is discontinued.</li>
                <li>It is impossible for us to test our software on every server platform and every server configuration it may encounter. Therefore, if our support staff is unable to get our software up and running on
                     your server within the first 14 days after purchase, we will happily give you a refund. You will need to provide us with full access to your hosting / server environments in order for us 
                     to fully debug and install the software. If you cannot provide us this access in a timely and cooperative manner, then we will not be able to give you a refund.</li></ul><br />
                     <h5>WE DO NOT ISSUE REFUNDS UNDER THE FOLLOWING CIRCUMSTANCES</h5><hr style={{width:'100%', color:'black'}} /><p style={{textTransform:'initial'}}>You are eligible for a refund AND: -</p><ul style={{listStyle:'square'}}>
                        <li>You've changed your mind.</li><li>You've decided that our product is "Not what I need".</li><li>You've found a different solution.</li>
                        <li>You've made some modifications and now our product isn't working.</li><li>We have a working solution for you but you don't want to implement it.</li>
                        <li>We have deployed a dEX for you and we can notice on the blockchain that your exchange has noticeable volume.</li></ul><br /><h5>TERMINATION WITHOUT REFUND</h5><hr style={{width:'100%', color:'black'}}/>
                        <p style={{textTransform:'initial'}}>We reserve the right to terminate accounts without refund if you violate our Terms and Conditions or become verbally abusive to our staff, other users of our site or our associates. 
                            In case such user tries to use our services again, we reserve the right to not provide them.</p><br /><h5>ACCOUNT TERMINATION</h5><hr style={{width:'100%', color:'black'}} />
                            <p style={{textTransform:'initial'}}>The Buyer understands that access to software is terminated when the refund is processed.</p><br /><h5>REFUND PROCESSING</h5><hr style={{width:'100%', color:'black'}} />
                            <p style={{textTransform:'initial'}}>Refunds will be processed in the same cryptocurrency you used to pay. Refunds are issued up to 14 business days after the approval date. The actual date of receiving the refund depends on the 
                                method of payment. Usually Tether (USDT) refunds are credited within few hours.</p>
                                <span style={{fontWeight:'bold'}}>NOTICE</span><p style={{textTransform:'initial'}}>The moment you pay your cryptocurrency is converted into a stable coin. A stable coin is a cryptocurrency which holds the same value (
                                    e.g $1). Due to high volatility, your cryptocurrency of choice may change in price from the moment you've made a purchase to the moment we've issued a refund. As a result, your refund 
                                    will be different from what you have initially paid. The price to determine your refund will be defined by our payment processor, Coinbase, Inc. Due to legal restrictions, all refunds will 
                                    be processed to the same address you've paid with. WE CANNOT REFUND YOU TO A DIFFERENT WALLET ADDRESS. We cannot refund you if you sent your cryptocurrency payment from an exchange. No exceptions.
                                    </p><br /><h5>PARTIAL REFUND</h5><hr style={{width:'100%', color:'black'}} /><p style={{textTransform:'initial'}}>If you have purchased a product of ours that has been deployed to a blockchain, we won't be able to issue a full
                                         refund. Instead, we will subtract the payment fees for deployment and refund you the rest.</p><p style={{textTransform:'initial'}}>If you have purchased two products of ours 
                                            (e.g frontend and smart-contracts deployment) but you want to refund one, we will offer you a partial refund by subtracting from your total the fees you've paid for the product 
                                            you are not refunding.</p><br/><h5>BLOCKCHAIN PRODUCTS</h5><hr style={{width:'100%', color:'black'}} />
                                            <p style={{textTransform:'initial'}}>If you ask for a refund on a blockchain deployed product, you agree that you will invalidate the product by setting "setFeeToSetter" 
                                                to an address provided by us. This will help us ensure that, if you ever think of continuing using our product after asking for a refund, 
                                                all exchange fees paid earned will not go to you. When you set "setFeeToSetter" to the address provided by us, you also agree to transfer 
                                                all rights to Forkswap developers. Failing to meet this criteria will result in no refund for blockchain products.</p></div>

          </div>
        </div>
      </div>
      </div>

      {/* brand-area */}
    </Layout>
  );
};

export default ServicesDetailsPageThree;
