import { RIGHT_ARROW } from "../lib/assets";

export const FEATURE_TWO_LIST = [
  {
    id: 1,
    icon: "flaticon-profit",
    title: "Growing Business",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 2,
    icon: "flaticon-investment",
    title: "Finance Investment",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 3,
    icon: "flaticon-taxes",
    title: "Tax Advisory",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
];

export const FEATURE_THREE_LIST = [
  {
    id: 1,
    title: "DEX Creator",
    subtitle:
      "We can make your own decetranlized exchange within 2-4 working days.",
    href: "/contact",
    src: RIGHT_ARROW,
  },
  {
    id: 2,
    title: "Custom Dapp",
    subtitle:
      "Got an idea for something cool? Share it with us and we will quote building it. Launchpads, token lockers, tokens, anything you can imagine.",
    href: "/contact",
    src: RIGHT_ARROW,
  },
  {
    id: 3,
    title: "Custom Consultancy",
    subtitle:
      "Is your project growing? Do you need custom consultancy related to DeFi, Blockchain, Crypto or WEB3? It's us. ",
    href: "/contact",
    src: RIGHT_ARROW,
  },
  {
    id: 4,
    title: "Royal Consultancy",
    subtitle:
      "Premier Blockchain Consultancy with a commitment to excellence and discretion.",
    href: "/contact",
    src: RIGHT_ARROW,
  },
];

export const FEATURE_FOUR_LIST = [
  {
    id: 1,
    icon: "flaticon-life-insurance",
    title: "More coverage",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 2,
    icon: "flaticon-handshake",
    title: "Less hassle",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 3,
    icon: "flaticon-money",
    title: "Faster benefits",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
];

export const FEATURE_ONE_LIST = [
  {
    id: 1,
    icon: "flaticon-puzzle-piece",
    title: "Quality Services",
    subtitle: "We promise to deliver fast & high-end, complete products and services.",
  },
  {
    id: 2,
    icon: "flaticon-inspiration",
    title: "Innovative",
    subtitle: "We promise to keep deliver up-to date solutions, with latest industry practices.",
  },
  {
    id: 3,
    icon: "flaticon-profit",
    title: "Success Oriented",
    subtitle: "We promise to stay success oriented, even after the sale process ends such as providing post-sale support and maintenance.",
  },
];
