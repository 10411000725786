import React, { useState } from "react";
import { CONTACT_IMG } from "../../lib/assets";

export const ContactAreaInner = () => {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const [error, setError] = useState('')
  const [submitted, setSubmitted] = useState(false)


  function onSubmit(e){
    e.preventDefault();
    e.stopPropagation();

    fetch("https://formcarry.com/s/9b2kumvyp7", {
      method: 'POST',
      headers: { 
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ name, email, message })
    })
    .then(response => response.json())
    .then(response => {
      if (response.code === 200) {
        setSubmitted(true)
        alert("We received your submission, thank you!");
      }
      else if(response.code === 422){
        // Field validation failed
        setError(response.message)
      }
      else {
        // other error from formcarry
        setError(response.message)
      }
    })
    .catch(error => {
      // request related error.
      setError(error.message ? error.message : error);
    });
  }


  return (
    <section className="inner-contact-area pt-120 pb-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <p style={{textAlign:'center'}}>Got an inquiry? Send us an email using the form below</p>
          </div>
        </div>

    
        <div className="row align-items-center">
          <div className="col-lg-12">
             <form onSubmit={(e) => onSubmit(e)} style={{width:'100%', maxWidth:'600px', margin:'0 auto', display:'block'}}>
    
      <div className="formcarry-block">
        <label htmlFor="name">Full Name</label>
        <input style={{width:'100%'}} type="text" value={name} onChange={(e) => setName(e.target.value)} id="name" placeholder="Your first and last name" />
      </div>
      
      <div className="formcarry-block">
        <label htmlFor="email">Your Email Address</label>
        <input  style={{width:'100%'}} type="email" value={email} onChange={(e) => setEmail(e.target.value)} id="email" placeholder="john@doe.com" />
      </div>
      
      <div className="formcarry-block">
        <label htmlFor="message">Your message</label>
        <textarea  style={{width:'100%'}} value={message} onChange={(e) => setMessage(e.target.value)} id="message" placeholder="Enter your message..."></textarea>
      </div>
      
      <div className="formcarry-block">  
        {submitted == true ? 'Submitted' :         <button type="submit" style={{background:'blue', color:'white'}}>Send</button>
      }
      </div>
    </form>
          </div>


        </div>
      </div>
    </section>
  );
};
