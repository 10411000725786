import React from "react";
import { Layout } from "../../layouts/Layout";
import {
  CHECK_ICON,
  SD_WORKING_IMG01,
} from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandSeven } from "../../components/Brand/BrandSeven";
import SERVICES_DETAILS03 from '../../assets/img/services/services_details03c.jpg'

const ServicesDetailsPageThree = () => {
  return (
    <Layout breadcrumb={"Services"} title={"Service Details"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src={SERVICES_DETAILS03} alt="" />
          </div>
          <div className="services-details-content">
            <h2 className="title">Build a High-Performance POA EVM Blockchain</h2>
            <p style={{textTransform:'inherit'}}>
           <span style={{fontWeight:'bold'}}>    5 seconds target block time, block explorer via Blockscout & RPC.               </span> <br />


Proof of Authority (POA) networks offer an efficient and scalable solution for private and consortium blockchains. We specialize in developing POA EVM chains with a 5-second target block time, integrated with Blockscout block explorer and RPC endpoints.            </p>
            <div className="sd-inner-wrap sd-inner-wrap-three">
              <div className="row align-items-center">
                <div className="col-43">
                  <div className="services-details-list-two">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-piggy-bank"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Earn Fees</h5>
                          <p>
                            Allow nodes to make money from transaction fees
                          </p>
                        </div>
                      </li>
                      {/* <li>
                        <div className="icon">
                          <i className="flaticon-calculator"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Liquidity Mining </h5>
                          <p>
                            Allow Liquidity providers to earn from your dex.
                          </p>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-57">
                  <div className="content">
                    <h3 className="title-two">
                    Why Choose Our POA EVM development services?
                    </h3>
                    <p>
                      Forkswap has built numerous of EVM chains and has the experience to deliver a POA chain within a week.
                    </p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Proven Technology.
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        RPC endpoints for seamless integration with wallets and dApps.
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Custom Goverance Nodes
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Customizable
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="industrial-working-wrap">
              <h2 className="title-two">              Innovate with Confidence on a Reliable Blockchain Platform

              </h2>
              <p className="info-one" style={{textTransform:"inherit"}}>

Whether you're building a supply chain solution, a private network, or any blockchain application requiring speed and reliability, our POA EVM chain provides the ideal foundation.

              </p>
              
              <a href="/contact">
              <button className="submit-btn" style={{marginTop:'1rem'}}>
              Contact Forkswap
            </button>
            </a>
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
      <BrandSeven />
    </Layout>
  );
};

export default ServicesDetailsPageThree;
