import React from "react";
import { Layout } from "../../layouts/Layout";
import {
  CHECK_ICON,
  SD_WORKING_IMG01,
  SD_WORKING_IMG02,
} from "../../lib/assets";
import SERVICES_DETAILS03 from '../../assets/img/services/services_details03b.jpg'
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandSeven } from "../../components/Brand/BrandSeven";

const ServicesDetailsPageThree = () => {
  return (
    <Layout breadcrumb={"Services"} title={"Service Details"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src={SERVICES_DETAILS03} alt="" />
          </div>
          <div className="services-details-content">
            <h2 className="title">Raise funds in USDT, ETH, BNB, and more.</h2>
            <p style={{textTransform:'inherit'}}>
           <span style={{fontWeight:'bold'}}>  Accelerate Your Project's Growth with Our Crypto Launchpad Solutions.</span> <br />
        In today's competitive crypto landscape, securing funding is crucial for the success of any blockchain project. Our Crypto Launchpad development
            services provide a comprehensive platform for projects to raise capital in various cryptocurrencies like USDT, ETH, BNB, and more.          
        
      </p>
            <div className="sd-inner-wrap sd-inner-wrap-three">
              <div className="row align-items-center">
                <div className="col-43">
                  <div className="services-details-list-two">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-piggy-bank"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Affordable</h5>
                          <p>
                          Kickstart for a low upfront & % fee of funds raised

                          </p>
                        </div>
                      </li>
                      {/* <li>
                        <div className="icon">
                          <i className="flaticon-calculator"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Liquidity Mining </h5>
                          <p>
                            Allow Liquidity providers to earn from your dex.
                          </p>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-57">
                  <div className="content">
                    <h3 className="title-two">
                    Why Choose Our Launchpad?
                    </h3>
                    <p>
                      Over $3m have been raised by Forkswap-built launchpad. Our field-tested launchpad solution let's you deploy a presale quick and fundraise.
                    </p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Proven Technology.
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Ownership & Open Source
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        User friendly
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Customizable
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="industrial-working-wrap">
              <h2 className="title-two">Empower Your Vision with the Right Funding

              </h2>

              <p className="info-two" style={{textTransform:"inherit"}}>
              Our Crypto Launchpad not only facilitates fundraising but also builds a community around your project. Let us help you build the paltform for investors and turn your innovative ideas into reality.



              </p>
              <a href="/contact">
              <button className="submit-btn" style={{marginTop:'1rem'}}>
              Contact Forkswap
            </button>
            </a>
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
      <BrandSeven />
    </Layout>
  );
};

export default ServicesDetailsPageThree;
