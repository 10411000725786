import React from "react";
import { Layout } from "../../layouts/Layout";
import {
  CHECK_ICON,
  SD_WORKING_IMG01,
} from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandSeven } from "../../components/Brand/BrandSeven";
import SERVICES_DETAILS03 from '../../assets/img/services/services_details03d.jpg'

const ServicesDetailsPageThree = () => {
  return (
    <Layout breadcrumb={"Services"} title={"Service Details"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src={SERVICES_DETAILS03} alt="" />
          </div>
          <div className="services-details-content">
            <h2 className="title">Launch your own NFT collection in a sale-format.</h2>
            <p style={{textTransform:'inherit'}}>
           <span style={{fontWeight:'bold'}}>             Enter the Thriving NFT Market with Your Own Custom Collection.           </span> <br />



Non-Fungible Tokens (NFTs) have revolutionized digital ownership and creativity. Our NFT development services enable artists, creators, and businesses to launch their own NFT collections, complete with sale mechanisms and marketplace integration.            
            </p>
<div className="sd-inner-wrap sd-inner-wrap-three">
              <div className="row align-items-center">
                <div className="col-43">
                  <div className="services-details-list-two">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-piggy-bank"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Fundraise Fast</h5>
                          <p>
                            Dapp capable to scale up to 10k users / minute.
                          </p>
                        </div>
                      </li>
                      {/* <li>
                        <div className="icon">
                          <i className="flaticon-calculator"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Liquidity Mining </h5>
                          <p>
                            Allow Liquidity providers to earn from your dex.
                          </p>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-57">
                  <div className="content">
                    <h3 className="title-two">
                    Why Choose Our NFT solution?
                    </h3>
                    <p>
                      Launch your NFT collection within a week on any EVM chain. 
                    </p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Proven Technology.
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Metadata SAFE (IPFS)
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Marketplace compatible (e.g OpenSea)
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        NFTs custom whitelists
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="industrial-working-wrap">
              <h2 className="title-two">                           Monetize Your Creativity and Engage Your Audience


              </h2>
              <p className="info-one" style={{textTransform:"inherit"}}>


We provide end-to-end support to launch your NFT collection, from conceptualization to deployment. Tap into new revenue streams and connect with a global community of collectors and enthusiasts.
              </p>
              
              <a href="/contact">
              <button className="submit-btn" style={{marginTop:'1rem'}}>
              Contact Forkswap
            </button>
            </a>
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
      <BrandSeven />
    </Layout>
  );
};

export default ServicesDetailsPageThree;
