import React from "react";
import { Link } from "react-router-dom";
import { Odometer } from "../Odometer/Odometer";
import {
  INNER_ABOUT_IMG01,
  INNER_ABOUT_IMG02,
  INNER_ABOUT_SHAPE01,
} from "../../lib/assets";

export const AboutSeven = () => {
  return (
    <section className="about-area-seven pt-120 pb-120">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-9 order-0 order-lg-2">
            <div className="about-img-seven-wrap">
              <img
                src={INNER_ABOUT_IMG01}
                alt=""
                data-aos="fade-right"
                data-aos-delay="0"
              />
              <img
                src={INNER_ABOUT_IMG02}
                alt=""
                data-aos="fade-up"
                data-aos-delay="300"
              />
              <img
                src={INNER_ABOUT_SHAPE01}
                alt=""
                className="shape"
                data-aos="zoom-in"
                data-aos-delay="500"
              />
              <div
                className="experience-wrap"
                data-aos="fade-left"
                data-aos-delay="0"
              >
                <h2 className="title">4</h2>
                <p>Years Of Operations</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about-content-seven">
              <div className="section-title mb-30">
                <span className="sub-title">Who We are</span>
                <h2 className="title">
                  Innovative Business Solutions For Blockchain Companies
                </h2>
              </div>
              <p>
                We provide a range of services, from software development to advisory and consulting services, tailored for your needs. 
                
              </p>

              <div className="success-wrap-two">
                <ul className="list-wrap">
                  <li>
                    <div className="icon">
                      <i className="flaticon-investment"></i>
                    </div>
                    <div className="content">
                      <h2 className="count">
                        <Odometer end={300} />+
                      </h2>
                      <p>Clients</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="flaticon-business-presentation"></i>
                    </div>
                    <div className="content">
                      <h2 className="count">
                        <Odometer end={100} />%
                      </h2>
                      <p>Satisfaction</p>
                    </div>
                  </li>
                </ul>
              </div>

              <p className="info-two">
                Forkswap has been operating since 2020, and its founder has been in crypto since early 2017. 
                We fully understand what it takes to circumnavigate the blockchain industry, and can provide tailored solutions for your needs.
                Forkswap operates by understanding your requirements, and co-operating on building solutions.
              </p>

              <Link to="/contact" className="btn btn-three">
                Contact With Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
