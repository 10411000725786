import React from "react";
import { Layout } from "../../layouts/Layout";
import {
  CHECK_ICON,
  SD_WORKING_IMG01,
  SD_WORKING_IMG02,
  SERVICES_DETAILS03,
} from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandSeven } from "../../components/Brand/BrandSeven";

const ServicesDetailsPageThree = () => {
  return (
    <Layout breadcrumb={"Services"} title={"Service Details"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src={SERVICES_DETAILS03} alt="" />
          </div>
          <div className="services-details-content">
            <h2 className="title">Earn money from running the most successful DEX technology.</h2>
            <p style={{textTransform:'inherit'}}>
           <span style={{fontWeight:'bold'}}> Unlock the Power of Decentralized Trading with Our Uniswap V2-Based DEX Development.</span> <br />

In the rapidly evolving world of cryptocurrency, decentralized exchanges (DEXs) have emerged as a cornerstone for secure and transparent trading. By leveraging Uniswap V2 technology, we offer cutting-edge DEX development services that empower you to run your own decentralized exchange platform.
            </p>
            <div className="sd-inner-wrap sd-inner-wrap-three">
              <div className="row align-items-center">
                <div className="col-43">
                  <div className="services-details-list-two">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-piggy-bank"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Earn Fees</h5>
                          <p>
                            Make money on every trade
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-calculator"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Liquidity Mining </h5>
                          <p>
                            Allow Liquidity providers to earn from your dex.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-57">
                  <div className="content">
                    <h3 className="title-two">
                    Why Choose Our DEX Development Services?
                    </h3>
                    <p>
                      Beside having binance-listed clients choosing Forkswap for building their dex, here are further benefits
                    </p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Proven Technology.
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Built on the battle-tested Uniswap V2 protocol
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Secure
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Customizable
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="industrial-working-wrap">
              <h2 className="title-two">Revenue Generation:
              </h2>
              <p className="info-one" style={{textTransform:"inherit"}}>
              Earn fees from trades executed on your platform, creating a sustainable income stream. Forkswap will build Intuitive design for seamless user experience, attracting more traders to your platform.
              </p>
              <div className="row">
                <div className="col-md-12">
                  <img src={SD_WORKING_IMG01} alt="defillama" />
                  <a href="https://defillama.com/protocol/uniswap-v2?fees=true#information" style={{textAlign:'center', color:'black', width:'100%'}} target="_blank" rel="noreferrer">
                  Source: DefiLlama</a>
                </div>
                {/* <div className="col-md-6">
                  <img src={SD_WORKING_IMG02} alt="" />
                </div> */}
              </div>
              <p className="info-two" style={{textTransform:"inherit"}}>
              By launching your own DEX, you position yourself at the forefront of the crypto revolution. Our expert team will guide you through every step, from conceptualization to deployment, ensuring a smooth and successful launch.


              </p>
              <a href="/contact">
              <button className="submit-btn" style={{marginTop:'1rem'}}>
              Contact Forkswap
            </button>
            </a>
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
      <BrandSeven />
    </Layout>
  );
};

export default ServicesDetailsPageThree;
