import React from "react";
import { Layout } from "../../layouts/Layout";
import {
  CHECK_ICON,
  SD_WORKING_IMG01,
} from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandSeven } from "../../components/Brand/BrandSeven";
import SERVICES_DETAILS03 from '../../assets/img/services/services_details03e.jpg'

const ServicesDetailsPageThree = () => {
  return (
    <Layout breadcrumb={"Services"} title={"Service Details"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src={SERVICES_DETAILS03} alt="" />
          </div>
          <div className="services-details-content">
            <h2 className="title">Need general consultancy?</h2>
            <p style={{textTransform:'inherit'}}>
           <span style={{fontWeight:'bold'}}>  You are in the right place.          </span> <br />

            Whether it is for personal matters or business related, you are in the right place. We offer consultancy services to circumnavigate the blockchain space, from research & development to 
            business advisory.
      </p>
            <div className="sd-inner-wrap sd-inner-wrap-three">
              <div className="row align-items-center">
                <div className="col-57">
                  <div className="content">
                    <h3 className="title-two">
                    Why Choose Forkswap?
                    </h3>
                    <p>
                      We strongly believe in crypto & blockchain. Our passion makes us stay up to date with the latest trends and policies, allowing us to provide the best consulting service. 
                    </p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Pay in Installments
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Professional Service
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Advanced Research Reports
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Tailored for your needs
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="industrial-working-wrap">
              <h2 className="title-two">             So what are you waiting for?

              </h2>
              <p className="info-one" style={{textTransform:"inherit"}}>

                Contact us and let's schedule a meeting to discuss more.

              </p>
              
              <a href="/contact">
              <button className="submit-btn" style={{marginTop:'1rem'}}>
              Contact Forkswap
            </button>
            </a>
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
      <BrandSeven />
    </Layout>
  );
};

export default ServicesDetailsPageThree;
